import Home from './Home';
import About from './About';
import Resume from './Resume';
import Projects from './Projects';
import Expertise from './Expertise';
// import other pages here

export default [
  {
    path: '/',
    component: Home,
    name: 'Home'
  },
  {
    path: '/Expertise',
    component: Expertise,
    name: 'Expertise'
  },
  {
    path: '/Projects',
    component: Projects,
    name: 'Projects'
  },
  {
    path: '/Resume',
    component: Resume,
    name: 'Resume'
  },
  {
    path: '/About',
    component: About,
    name: 'About'
  },
  // add other pages here
];