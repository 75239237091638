import '../css/MichelleTerryCreativeWeb.css';
import Section from '../../components/section';
import TextBlock from '../../components/textblock';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import Animator from '../../components/Animator';
import Section2 from '../../components/section2';

function MTCWebI() {
    return (
        <div className="MTCWebI">
            <FetchAndRenderMarkdown 
                className="Header"
                url='/projects/MichelleTerryCreativeWebI/Header.md'
                Component={TextBlock} 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
            />
            <FetchAndRenderMarkdown 
                url='/projects/MichelleTerryCreativeWebI/Section1.md' 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                images={[{ images: ['/projects/MichelleTerryCreativeWebI/Carousel.png'], imagePosition: 'left'}]}
                Component={Section2} 
            />
            <FetchAndRenderMarkdown 
                url='/projects/MichelleTerryCreativeWebI/Section2.md' 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                images={[{ images: ['/projects/MichelleTerryCreativeWebI/GoogleForm.png'], imagePosition: 'right'}]}
                Component={Section2} 
            />
        </div>
    );
}

export default MTCWebI;