import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Resume.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import downloadButton from '../assets/downloadButton.png';
import resumePDF from '../assets/Resume.pdf';
import Animator from '../components/Animator';

function Resume() {
    return (
        <div className="Resume">
            <Helmet>
                <title>Zane Saul's Resume - Full Stack Software Engineer</title>
                <meta name="description" content="Explore Zane Saul's professional resume, showcasing expertise in full-stack development, project management, and innovative software solutions." />
                <meta name="keywords" content="professional resume, full-stack developer, software engineer, React, Node.js, project management" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Zane Saul's Resume - Full Stack Software Engineer" />
                <meta property="og:description" content="Download and view Zane Saul's professional resume to learn more about his expertise in Software Engineering and project management." />
                <meta property="og:image" content={`${window.location.origin}/images/downloadButton.png`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
            </Helmet>
            <div className="download-section">
                <a href={resumePDF} download="Resume.pdf">
                    <img src={downloadButton} alt="Download button" className="download-button" />
                </a>
                <p className="download-text">Download Resume</p>
            </div>
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"
                url="/Resume.md" 
                Component={TextBlock} 
                wrapperClassName="Text-Wrapper" 
                componentClassName="Text-Section" 
                breakBySection 
            />
        </div>
    );
}

export default Resume;