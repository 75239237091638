import React from 'react';
import { Helmet } from 'react-helmet';
import './css/About.css';
import profilePic from '../assets/profilePic2.jpeg';
import emailIcon from '../assets/emailIcon.png';
import linkedinIcon from '../assets/linkedInIcon.png';
import instagramIcon from '../assets/instagramIcon.png';
import githubIcon from '../assets/githubIcon.png';
import contactCard from '../assets/contactCard.png';
import contactCardVCF from '../assets/contactCard.vcf';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import data from '../assets/data.json';

function About() {
  return (
    <div className="About">
      <Helmet>
        <title>Zane M. Saul - Full Stack Software Engineer</title>
        <meta name="description" content="Discover Zane M. Saul, a Full Stack Software Engineer with a passion for technology and innovation. Learn about my journey, projects, and the expertise I bring to software development." />
        <meta name="keywords" content="Zane M. Saul, Software Engineer, Full Stack Developer, Technology, Innovation, Projects, Secure Development" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`${window.location.origin}/about`} />
        <meta property="og:title" content="About Zane M. Saul - Full Stack Software Engineer" />
        <meta property="og:description" content="Meet Zane M. Saul, Full Stack Software Engineer. Explore my professional journey, dedication to technology, and the projects that showcase my expertise." />
        <meta property="og:image" content={`${window.location.origin}${profilePic}`} />
        <meta property="og:url" content={`${window.location.origin}/about`} />
        <meta property="og:type" content="profile" />
        <meta property="og:profile:first_name" content="Zane" />
        <meta property="og:profile:last_name" content="Saul" />
        <meta property="og:profile:username" content="ZaneMSaul" />
    </Helmet>
      <div className='image-section'>
        <img className="About-image" src={profilePic} alt="profilePic" />
        <div className="icon-section">
          <a href={`mailto:${data.email}`} target="_blank">
            <img className="email-icon" src={emailIcon} alt="Email" />
          </a>
          <a href={data.socialProfiles.linkedin} target="_blank">
            <img className="linkedin-icon" src={linkedinIcon} alt="LinkedIn" />
          </a>
          <a href={data.socialProfiles.instagram} target="_blank">
            <img className="instagram-icon" src={instagramIcon} alt="Instagram" />
          </a>
          <a href={data.socialProfiles.github} target="_blank">
            <img className="github-icon" src={githubIcon} alt="GitHub" />
          </a>
          <a href={contactCardVCF} download="ContactCard.vcf" target="_blank">
            <img className="contact-icon" src={contactCard} alt="Contact Card" />
          </a>
        </div>
      </div>
      <p className="About-text">
        <FetchAndRenderMarkdown url='/aboutMe.md' Component={TextBlock} />
      </p>
    </div>
  );
}

export default About;