import React from 'react';
import './css/textblock.css';
import './css/recommendation.css';

function Recommendation({ id, text, name, position, date, image, link }) {
    return (
        <div className='recommendation'>
            <div {...(id ? { id: id } : {})} className="GradientBorderWrapper">
                <div className="TextBlock">
                    <div className='profilePic'>
                        <img src={image} alt={name} />
                    </div>
                    <div className='text'>
                        <h3>{name}</h3>
                        <span>{position}</span>
                        <br />
                        <span>{date}</span>
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Recommendation;