import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Projects.css';
import ProjectSummary from '../components/projectsummary';
import rightArrow from '../assets/RightArrow.png';
import projects from '../assets/projectData.json';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import useScrollToAnchor from '../components/useScrollToAnchor';

function Projects() {

    useScrollToAnchor();

    return (
        <div className="Projects">
            <Helmet>
                <title>Zane Saul's Projects - Innovative Software Solutions</title>
                <meta name="description" content="Discover Zane Saul's portfolio of innovative projects. Featuring solutions built with React and JavaScript, each project showcases a unique approach to solving complex software development challenges." />
                <meta name="keywords" content="portfolio, personal projects, React, JavaScript, software development, innovative solutions" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Zane Saul's Projects - Discover Innovative Software Solutions" />
                <meta property="og:description" content="Explore the innovative software solutions created by Zane Saul, featuring projects built with React, JavaScript, and more." />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            {projects.map((project, index) => (
                <FetchAndRenderMarkdown 
                    key={project.id || index}
                    url={project.summary} 
                    Component={ProjectSummary} 
                    image= {project.image}
                    title= {project.title}
                    Icon= {rightArrow} 
                    link= {project.link}
                    id={project.id}
                    onError={(error) => {
                        console.error(`Failed to fetch or render markdown for project ${project.title}:`, error);
                    }}
                />
            ))}
        </div>
    );
}

export default Projects;