import React from 'react';
import './css/nametitle.css';

const NameTitle = () => {
    return (
        <div className="name-title">
            <h1>Zane Saul</h1>
            <h2>Full Stack Software Engineer</h2>
        </div>
    );
};

export default NameTitle;