import React, { createContext } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import pages from './pages/pagesConfig';
import hiddenPages from './pages/hiddenPagesConfig';
import marble from './assets/blackgoldMarble.jpeg';
import Navigation from './components/Navigation';
import RoutePathProvider from './components/RoutePathProvider';

const RoutePathContext = createContext('/');

function App() {  
  return (
    <Router>
      <Helmet>
          <html lang="en" />
          <title>Zane M. Saul | Full Stack Developer & Technology Innovator</title>
          <meta name="description" content="Explore the portfolio of Zane M. Saul, Full Stack Developer specializing in React, Node.js, and cutting-edge web technologies. Discover projects that blend innovation with functionality." />
          <meta name="keywords" content="Zane Saul, Full Stack Developer, React Developer, Software Engineer, Portfolio, Web Development, Technology Innovator" />
          <meta name="author" content="Zane M. Saul" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://zanesaul.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Zane M. Saul | Full Stack Developer & Technology Innovator" />
          <meta property="og:description" content="Explore the innovative and creative world of Zane M. Saul, a Full Stack Developer passionate about building modern, responsive web applications." />
          <meta property="og:url" content="https://zanesaul.com/" />
          <meta property="og:site_name" content="Zane M. Saul's Portfolio" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Zane M. Saul | Full Stack Developer & Technology Innovator" />
          <meta name="twitter:description" content="Explore the portfolio of Zane M. Saul, Full Stack Developer specializing in React, Node.js, and cutting-edge web technologies." />
          <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1601348682741432326/XAdRHL_X_400x400.jpg" />
          <meta name="twitter:creator" content="@zane_m_s" />
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "Zane M. Saul",
                "url": "https://zanesaul.com",
                "jobTitle": "Full Stack Software Engineer & Technology Innovator",
                "worksFor": {
                  "@type": "Organization",
                  "name": "Independent"
                },
                "sameAs": [
                  "https://www.linkedin.com/in/zane-saul/",
                  "https://github.com/ZaneMSaul",
                  "https://twitter.com/zane_m_s"
                ]
              }
            `}
          </script>
      </Helmet>
      <RoutePathProvider RoutePathContext={RoutePathContext}>
        <div className="App" style={{backgroundImage: `url(${marble})`}}>
          <div className='header'>
            <Navigation RoutePathContext={RoutePathContext} />
          </div>
          <div className='body'>
            <Routes>
              {pages.map((page, index) => (
                <Route key={index} path={page.path} element={<page.component />} />
              ))}
              {hiddenPages.map((page, index) => (
                <Route key={index} path={page.path} element={<page.component />} />
              ))}
            </Routes>
          </div>
          <div className='footer'>
            © 2024 by Zane Saul
          </div>
        </div>
      </RoutePathProvider>
    </Router>
  );
}

export default App;