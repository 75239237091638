import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Home.css';
import Headshot from '../components/headshot';
import myHeadshot from '../assets/headshot.jpeg';
import NameTitle from '../components/nametitle';
import TextBlock from '../components/textblock';
import pages from './pagesConfig';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className="Home">
            <Helmet>
                <title>Zane Saul - Full Stack Software Engineer Portfolio</title>
                <meta name="description" content="Experienced Full Stack Software Engineer specializing in React, Node.js, and API development. Discover projects and achievements that demonstrate expertise in building scalable solutions." />
                <meta name="keywords" content="Full Stack Developer, React, Node.js, API Development, JavaScript, C#, SQL" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Zane Saul - Full Stack Software Engineer Portfolio" />
                <meta property="og:description" content="Discover the portfolio of Zane Saul, a Full Stack Software Engineer with expertise in React, Node.js, and building scalable solutions." />
                <meta property="og:image" content={myHeadshot} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='headshot'>
                <Headshot className='headshot-container' imageUrl={myHeadshot} />
                <NameTitle />
                <div className='navigation'>
                    {pages.filter(page => page.path !== '/').map((page, index) => (
                        <Link key={index} to={page.path}>
                            <TextBlock text={page.name}></TextBlock>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Home;