// HeaderContent.js
import React from 'react';
import Headshot from './headshot';
import myHeadshot from '../assets/headshot.jpeg';
import NameTitle from './nametitle';
import './css/headercontent.css';

function HeaderContent() {
    return (
        <div className={`header headshot`}>
            <Headshot className='headshot-container' imageUrl={myHeadshot} />
            <NameTitle className='name-title' />
        </div>
    );
}

export default HeaderContent;