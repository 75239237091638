import React, { useState } from 'react';
import Recommendation from './recommendation';
import RecommendationsJSON from '../assets/recommendations.json'

function RecommendationsList({ Component = 'div', className, containerClassName='', containerComponent='div', id}) {
    const [recommendations, setRecommendations] = useState(RecommendationsJSON.Recommendations);

    const Wrapper = Component;

    return (
        <Wrapper {...(id ? { id: id } : {})} className={className} >
            {recommendations.sort((a, b) => new Date(b.date) - new Date(a.date)).map((rec, index) => (
                <containerComponent className={containerClassName}>
                    <Recommendation
                        key={index}
                        text={rec.recommendation}
                        name={rec.name}
                        position={rec.position}
                        date={rec.date}
                        image={rec.image}
                        link={rec.link}
                    />
                </containerComponent>
            ))}
        </Wrapper>
    );
}

export default RecommendationsList;