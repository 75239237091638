import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/Portfolio.css';
import TextBlock from '../../components/textblock';
import FetchAndRenderMarkdown from '../../components/FetchAndRenderMarkdown';
import useScrollToAnchor from '../../components/useScrollToAnchor';
import Animator from '../../components/Animator';

function Portfolio() {

    useScrollToAnchor();

    return (
        <div className="Portfolio">
            <Helmet>
                <title>Zane M. Saul's Portfolio - Full Stack Software Engineer</title>
                <meta name="description" content="Discover the portfolio of Zane M. Saul, Full Stack Software Engineer, featuring innovative projects that blend advanced design principles with cutting-edge technology. Explore my journey through software engineering, my commitment to SEO, and my passion for creating intuitive and impactful digital experiences." />
                <meta name="keywords" content="Zane M. Saul, Full Stack Software Engineer, React, Software Engineer, Portfolio, SEO, UI/UX Design, Technology" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Zane M. Saul's Portfolio - Full Stack Software Engineer" />
                <meta property="og:description" content="Explore the innovative projects and technical prowess of Zane M. Saul. Delve into a portfolio that demonstrates a seamless fusion of design and technology, showcasing my expertise in React development and commitment to creating meaningful digital experiences." />
                <meta property="og:url" content={`${window.location.origin}/portfolio`} />
                <meta property="og:type" content="website" />
            </Helmet>
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Header.md' 
                Component={TextBlock} 
                id='intro'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container"                 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section1.md' 
                Component={TextBlock} 
                id='principlesInDevelopment'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section2.md' 
                Component={TextBlock} 
                id='aesthetics'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section2-5.md' 
                Component={TextBlock} 
                id='seo'
            />
            <FetchAndRenderMarkdown 
                WrapperComponent={Animator}
                containerClassName="Animated-Container" 
                wrapperClassName={'Header-Wrapper'}
                className="Header"
                url='/projects/PersonalPorfolio/Section3.md' 
                Component={TextBlock} 
                id='conclusion'
            />
        </div>
    );
}

export default Portfolio;