import React from 'react';
import './css/headshot.css';

const Headshot = ({ imageUrl }) => {
    return (
        <div className="headshot-container">
                <img src={imageUrl} alt="Headshot" className="headshot-image" />
        </div>
    );
};

export default Headshot;