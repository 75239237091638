import React from 'react';
import { Helmet } from 'react-helmet';
import './css/Expertise.css';
import FetchAndRenderMarkdown from '../components/FetchAndRenderMarkdown';
import TextBlock from '../components/textblock';
import profilePic from '../assets/profilePic2.jpeg';
import Animator from '../components/Animator';
import Recommendations from '../components/RecommendationsList';
import useScrollToAnchor from '../components/useScrollToAnchor';

function Expertise() {

    useScrollToAnchor();

    return (
        <div className="Expertise">
            <Helmet>
                <title>Zane M. Saul - Full Stack Software Engineer</title>
                <meta name="description" content="Discover Zane M. Saul, a Full Stack Software Engineer with a passion for technology and innovation. Learn about my journey, projects, and the expertise I bring to software development." />
                <meta name="keywords" content="Zane M. Saul, Software Engineer, Full Stack Developer, Technology, Innovation, Projects, Secure Development" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={`${window.location.origin}/about`} />
                <meta property="og:title" content="About Zane M. Saul - Full Stack Software Engineer" />
                <meta property="og:description" content="Meet Zane M. Saul, Full Stack Software Engineer. Explore my professional journey, dedication to technology, and the projects that showcase my expertise." />
                <meta property="og:image" content={`${window.location.origin}${profilePic}`} />
                <meta property="og:url" content={`${window.location.origin}/about`} />
                <meta property="og:type" content="profile" />
                <meta property="og:profile:first_name" content="Zane" />
                <meta property="og:profile:last_name" content="Saul" />
                <meta property="og:profile:username" content="ZaneMSaul" />
            </Helmet>
            <h1>My Expertise</h1>
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section1.md" 
                wrapperClassName={"Section1-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                id = "section1"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section2.md" 
                wrapperClassName={"Row Section2-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                breakBySection
                id = "section2"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section3.md" 
                wrapperClassName={"Row Section3-Wrapper"}
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                breakBySection
                id = "section3"
            />
            <FetchAndRenderMarkdown 
                url="/projects/Expertise/section4.md" 
                WrapperComponent={Animator} 
                Component={TextBlock} 
                containerClassName="Animated-Container"
                id = "section4"
            />
            <div id='recommendations' className='Recommendations'>
                <h1>Testimonials</h1>
                <Recommendations
                    Component={Animator}
                    className="Recommendations-Wrapper"
                    containerClassName="Animated-Container"
                />
            </div>
        </div>
    );
}

export default Expertise;