import MTCWebI from './HiddenPages/MichelleTerryCreativeWeb';
import FAA from './HiddenPages/FinancialAnalysisApp';
import Porfolio from './HiddenPages/Portfolio';
// import other pages here

export default [
  {
    path: '/Projects/MTCWebI',
    component: MTCWebI,
    name: 'MTCWebI'
  },
  {
    path: '/Projects/FAA',
    component: FAA,
    name: 'FAA'
  },
  {
    path: '/Projects/Portfolio',
    component: Porfolio,
    name: 'Porfolio'
  },
  // add other pages here
];