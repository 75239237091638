import React from 'react';
import { Link } from 'react-router-dom';
import './css/projectsummary.css';

function ProjectSummary({ image, title, text, Icon, link, id }) {
    return (
        <div {...(id ? { id: id } : {})} className="ProjectSummary">
            <div className="column image-text-column">
                <div className="image-column">
                    <img src={image} alt={title} />
                </div>
                <div className="text-column">
                    <h1>{title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
            <div className="column icon-column">
                <Link to={link}>
                    <img className="icon" src={Icon} alt="icon" />
                    <button className="read-more">Read More</button>
                </Link>
            </div>
        </div>
    );
}

export default ProjectSummary;